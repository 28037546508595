.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #fff;
}

.circle-progress {
  stroke: #2158eb;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 18px;
  font-weight: bold;
  fill: rgba(255, 255, 255, 0.87);
}

.circle-text-pers {
  font-size: 8px;
  font-weight: bold;
  fill: rgba(255, 255, 255, 0.87);
}
